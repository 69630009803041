import { FC, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { flexDisplay } from "components/helpers/helpers";
import { tabs, navbarFont, routes } from "consts/consts";
import { NavItem } from "components/navBar/navBar";
import { NavContainerProps } from "components/navBar/navBar";

import MenuIcon from "assets/mobileMenu/menu-icon.svg";
import CloseIcon from "assets/mobileMenu/close-menu-icon.svg";
import Logo from "assets/logo.png";

export const MobileMenuContainer = styled.div<NavContainerProps>`
  ${flexDisplay("100vw", "auto", "column")};
  background-color: ${({ mainPage }) =>
    mainPage ? "rgba(255, 255, 255, 0.4)" : "#ffffff"};
  position: sticky;
  top: 0;
`;

export const MobileMenuTop = styled.div`
  ${flexDisplay("100%", "70px", "row-reverse")};
  align-items: center;
  justify-content: space-between;
`;

export const mobileIconStyle = (width: number, height: number) => `
width: ${width}px;
height: ${height}px;
cursor: pointer;
`;

export const MobileIcon = styled.img`
  ${mobileIconStyle(50, 50)};
`;

export const MobileLogo = styled.img`
  ${mobileIconStyle(110, 70)}
`;

export const MobileTabList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  ${flexDisplay("100%", "auto", "column")};
  ${navbarFont};
  align-items: flex-end;
`;

export const MobileNavbar: FC<{ mainPage?: boolean }> = ({ mainPage }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  return (
    <MobileMenuContainer mainPage={mainPage ? true : false}>
      <MobileMenuTop>
        <MobileIcon
          src={isMenuOpened ? CloseIcon : MenuIcon}
          alt="hamburger-menu"
          onClick={() => setIsMenuOpened(!isMenuOpened)}
        />
        <Link to={routes.mainPage.path}>
          <MobileLogo src={Logo} alt="Logo" />
        </Link>
      </MobileMenuTop>
      {isMenuOpened && (
        <MobileTabList>
          {tabs.map(tab => (
            <NavItem path={tab.path} label={tab.label} key={tab.label} />
          ))}
        </MobileTabList>
      )}
    </MobileMenuContainer>
  );
};
