import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Document, INLINES, MARKS } from "@contentful/rich-text-types";

export const renderRichText = (document: Document) => {
  if (!document) {
    return null;
  }

  const Bold = ({ children }: { children: any }) => (
    <span style={{ fontWeight: "bold" }}>{children}</span>
  );

  const options = {
    renderText: (text: string) => {
      return text
        .split("\n")
        .reduce((children: any, textSegment: any, index: any) => {
          return [...children, index > 0 && <br key={index} />, textSegment];
        }, []);
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node: any, children: any) => {
        return (
          <a href={node.data.uri} target="blank">
            {children}
          </a>
        );
      },
    },
    renderMark: {
      [MARKS.BOLD]: (text: any) => <Bold>{text}</Bold>,
    },
  };

  return documentToReactComponents(document, options);
};
