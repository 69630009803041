import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { navbarFont } from "consts/consts";
import { width } from "components/helpers/helpers";
import { Navigation } from "components/navBar/navBar";
import { Container, Description } from "components/siteContent/siteContent";
import { MobileNavbar } from "components/navBar/mobileNavBar";
import { getClient } from "components/contentful/useContentful";
import { LoadingPage } from "components/loadingPage/loadingPage";
import { renderRichText } from "components/contentful/renderRichText";

export const AboutTitle = styled.div`
  margin: 20px;
  ${navbarFont};
  font-size: 30px;
  ${width.mobile} {
    font-size: 24px;
  }
`;

export const AboutImage = styled.img`
  max-height: 500px;
  max-width: 70%;
  border-radius: 50px;
  ${width.mobile} {
    max-width: 90%;
    border-radius: 20px;
  }
`;

export const About: FC = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<[] | null | any>(null); //TODO: add images type

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getClient().getEntry("6tiBVlG0Y1aPt5gLm6unFI");
        setData(response.fields);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
      }
    };
    getData();
    window.scrollTo(0, 0);
    setIsMobile(!(document.documentElement.clientWidth > 640));
  }, []);

  if (isLoading) return <LoadingPage />;
  if (isError) return <p>Sorry, Something went wrong!</p>;
  return (
    <Container>
      {isMobile ? <MobileNavbar /> : <Navigation />}
      {data.tytul && <AboutTitle>{data.tytul}</AboutTitle>}
      {data.foto1 && (
        <AboutImage src={data.foto1.fields.file.url} alt="o-mnie-foto" />
      )}
      {data.opis1 && <Description>{renderRichText(data.opis1)}</Description>}
      {data.foto2 && (
        <AboutImage src={data.foto2.fields.file.url} alt="o-mnie-foto" />
      )}
      {data.opis2 && <Description>{renderRichText(data.opis2)}</Description>}
      {data.foto3 && (
        <AboutImage src={data.foto3.fields.file.url} alt="o-mnie-foto" />
      )}
      {data.opis3 && <Description>{renderRichText(data.opis3)}</Description>}
      {data.foto4 && (
        <AboutImage src={data.foto4.fields.file.url} alt="o-mnie-foto" />
      )}
      {data.opis4 && <Description>{renderRichText(data.opis4)}</Description>}
      {data.foto5 && (
        <AboutImage src={data.foto5.fields.file.url} alt="o-mnie-foto" />
      )}
    </Container>
  );
};
