import { FC, useState, useEffect } from "react";
import styled from "styled-components";
import {
  flexDisplay,
  selectPreviousIndex,
  selectNextIndex,
  width,
} from "components/helpers/helpers";
import { navbarFont, primaryFont, seeAlsoText } from "consts/consts";
import { Modal } from "components/siteContent/modal";
import { Navigation } from "components/navBar/navBar";
import { MobileNavbar } from "components/navBar/mobileNavBar";
import { PortfolioCard } from "components/portfolioCard/portfolioCard";
import { portfolioTabsType } from "views/Porfolio";
import { Document } from "@contentful/rich-text-types";
import { renderRichText } from "components/contentful/renderRichText";

export const Container = styled.div`
  ${flexDisplay("100wv", "auto", "column")}
  align-items: center;
  margin-bottom: 10%;
`;

export const Description = styled.div`
  font-size: 18px;
  text-align: center;
  width: 70%;
  margin: 30px 0;
  line-height: 2;
  ${primaryFont};
  ${width.tablet} {
  }
  ${width.mobile} {
    font-size: 12px;
    margin: 20px 0;
    width: 90%;
  }
`;

export const Gallery = styled.div`
  width: 70%;
  ${width.mobile} {
    width: 100%;
  }
`;

export const GalleryRow = styled.div`
  ${flexDisplay("100%", "auto", "row")};
`;

export const GalleryColumn = styled.div`
  width: 50%;
  padding: 0 10px;
  ${width.mobile} {
    padding: 0 5px;
  }
`;

export const GalleryImage = styled.img`
  margin-top: 20px;
  vertical-align: middle;
  width: 100%;
  cursor: zoom-in;
  ${width.mobile} {
    margin: 2%;
    width: 96%;
  }
`;

export const SeeAlsoContainer = styled.div`
  ${flexDisplay("100%", "auto", "row")};
  justify-content: space-evenly;
  ${width.mobile} {
    ${flexDisplay("80%", "auto", "column")};
  }
`;

export const SeeAlsoDescription = styled.span`
  margin: 150px 0 40px 0;
  ${navbarFont};
  font-size: 30px;
  text-align: center;
  width: 70%;
  ${width.mobile} {
    margin: 40px 0;
    font-size: 20px;
    width: 100%;
  }
`;

interface SiteContentProps {
  images: string[];
  description?: Document;
  seeAlso?: portfolioTabsType;
}

const SiteContent: FC<SiteContentProps> = ({
  images,
  description,
  seeAlso,
}) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsMobile(!(document.documentElement.clientWidth > 640));
  }, []);

  const onImageClick = ({
    currentTarget,
  }: {
    currentTarget: HTMLImageElement;
  }) => {
    setSelectedPhotoIndex(images.indexOf(currentTarget.alt));
    setIsModalOpened(true);
  };

  const previousPhoto = () =>
    setSelectedPhotoIndex(
      selectPreviousIndex(selectedPhotoIndex, images.length)
    );

  const nextPhoto = () =>
    setSelectedPhotoIndex(selectNextIndex(selectedPhotoIndex, images.length));

  const leftColumnImages = images.filter((val, index) => !(index % 2));
  const rightColumnImages = images.filter((val, index) => index % 2);

  return (
    <Container>
      {isMobile ? <MobileNavbar /> : <Navigation />}
      {description && <Description>{renderRichText(description)}</Description>}
      <Gallery>
        {!isMobile ? (
          <GalleryRow>
            <GalleryColumn>
              {leftColumnImages.map((image, index) => (
                <GalleryImage
                  src={image}
                  data-index={index}
                  alt={image}
                  key={index}
                  onClick={onImageClick}
                />
              ))}
            </GalleryColumn>
            <GalleryColumn>
              {rightColumnImages.map((image, index) => (
                <GalleryImage
                  src={image}
                  data-index={index}
                  alt={image}
                  key={index}
                  onClick={onImageClick}
                />
              ))}
            </GalleryColumn>
          </GalleryRow>
        ) : (
          images.map((image, index) => (
            <GalleryImage
              src={image}
              data-index={index}
              alt={image}
              key={image}
              onClick={onImageClick}
            />
          ))
        )}
      </Gallery>
      {seeAlso && (
        <>
          <SeeAlsoDescription>{seeAlsoText}</SeeAlsoDescription>
          <SeeAlsoContainer>
            {seeAlso?.map(card => (
              <PortfolioCard
                key={card.label}
                path={card.path}
                label={card.label}
                image={card.image}
              />
            ))}
          </SeeAlsoContainer>
        </>
      )}
      {isModalOpened && (
        <Modal
          images={images}
          selectedPhotoIndex={selectedPhotoIndex}
          previousPhoto={previousPhoto}
          nextPhoto={nextPhoto}
          closeModal={() => setIsModalOpened(false)}
        />
      )}
    </Container>
  );
};

export default SiteContent;
