import { FC, useState, useEffect } from "react";
import styled from "styled-components";
import LeftArrow from "assets/left-arrow.png";
import RightArrow from "assets/right-arrow.png";
import {
  flexDisplay,
  selectNextIndex,
  selectPreviousIndex,
} from "components/helpers/helpers";
import { Navigation } from "components/navBar/navBar";
import { centerContent } from "consts/consts";
import { LoadingPage } from "components/loadingPage/loadingPage";
import { MobileNavbar } from "components/navBar/mobileNavBar";
import { getClient } from "components/contentful/useContentful";

export const MainPageContainer = styled.div<{ imageUrl: string }>`
  position: fixed;
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${props => props.imageUrl});
  ${flexDisplay("100vw", "100vh", "row")}
`;

export const SideContainer = styled.div`
  ${flexDisplay("15vw", "100%", "column")}
  ${centerContent}
`;

export const CenterContainer = styled.div`
  ${flexDisplay("70vw", "100%", "column")}
  align-items: center;
`;

export const Icon = styled.img`
  cursor: pointer;
`;

export const MainPage: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState<[] | null | any>(null); //TODO: add images type
  const [isError, setIsError] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const contentID =
      document.documentElement.clientWidth > 640
        ? "3VVOvm05buJCLYIjvKkj8o"
        : "621Mi49eYSvrowajix3oYY";
    const getData = async () => {
      try {
        const response = await getClient().getEntry(contentID);
        setImages(response.fields);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
      }
    };
    setIsMobile(!(document.documentElement.clientWidth > 640));
    getData();
  }, []);

  useEffect(() => {
    const mainImageTimeout = setTimeout(nextPhoto, 5000);
    return () => clearTimeout(mainImageTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageIndex, images]);

  const nextPhoto = () =>
    setImageIndex(selectNextIndex(imageIndex, images.images.length));

  const prevPhoto = () =>
    setImageIndex(selectPreviousIndex(imageIndex, images.images.length));

  if (isLoading) return <LoadingPage />;
  if (isError) return <p>Sorry, Something went wrong!</p>;
  return (
    <MainPageContainer
      imageUrl={
        images.images[imageIndex].fields.file &&
        images.images[imageIndex].fields.file.url
      }
    >
      <SideContainer>
        {!isMobile && <Icon src={LeftArrow} onClick={prevPhoto} />}
      </SideContainer>
      <CenterContainer>
        {isMobile ? <MobileNavbar mainPage /> : <Navigation mainPage />}
      </CenterContainer>
      <SideContainer>
        {!isMobile && <Icon src={RightArrow} onClick={nextPhoto} />}
      </SideContainer>
    </MainPageContainer>
  );
};
