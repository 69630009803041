import { FC } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "consts/consts";
import { MainPage } from "views/MainPage";
import { About } from "views/About";
import { Contact } from "views/Contact";
import { Portfolio } from "views/Porfolio";
import { Concerts } from "views/Concerts";
import { Single } from "views/Single";
import { Reports } from "views/Reports";
import { Projects } from "views/Projects";
import { Branding } from "views/Branding";

const App: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.mainPage.path} element={<MainPage />} />
        <Route path={routes.projects.path} element={<Projects />} />
        <Route path={routes.portfolio.path} element={<Portfolio />} />
        <Route path={routes.concerts.path} element={<Concerts />} />
        <Route path={routes.single.path} element={<Single />} />
        <Route path={routes.branding.path} element={<Branding />} />
        <Route path={routes.reports.path} element={<Reports />} />
        <Route path={routes.about.path} element={<About />} />
        <Route path={routes.contact.path} element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
