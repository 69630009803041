import { createClient } from "contentful";

export const getClient = () => {
  const client = createClient({
    space: process.env.REACT_APP_SPACE_ID ? process.env.REACT_APP_SPACE_ID : "",
    accessToken: process.env.REACT_APP_CONTENT_PREVIEW_TOKEN
      ? process.env.REACT_APP_CONTENT_PREVIEW_TOKEN
      : "",
    host: "preview.contentful.com",
  });

  return client;
};
