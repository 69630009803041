import React, { FC, useRef, useState, useEffect } from "react";
import { getClient } from "components/contentful/useContentful";
import styled from "styled-components";
import emailjs from "@emailjs/browser";
import { flexDisplay, width } from "components/helpers/helpers";
import { Container } from "components/siteContent/siteContent";
import { Navigation } from "components/navBar/navBar";
import {
  EMAIL_ADRESS,
  INSTAGRAM_URL,
  INSTAGRAM_NAME,
  navbarFont,
  PHONE_NUMBER,
  contactDescription,
} from "consts/consts";
import PhoneIcon from "assets/phone-icon.svg";
import EmailIcon from "assets/email-icon.svg";
import InstagramIcon from "assets/instagram-icon.svg";
import { MobileNavbar } from "components/navBar/mobileNavBar";
import { LoadingPage } from "components/loadingPage/loadingPage";

export const ContactContainer = styled.div`
  ${flexDisplay("80%", "auto", "row")}
  ${width.mobile} {
    flex-direction: column;
    margin-top: 20px;
  }
`;

export const Column = styled.div`
  ${flexDisplay("50%", "auto", "column")};
  padding: 50px;
  align-items: center;
  ${width.mobile} {
    width: 100%;
    padding: 0;
  }
`;

export const Form = styled.form`
  ${flexDisplay("100%", "auto", "column")};
  gap: 5px;
  ${width.mobile} {
    margin-bottom: 40px;
  }
`;

export const inputStyle = (height: number) => `
width: 100%;
height: ${height}px;
padding: 12px 20px;
box-sizing: border-box;
border: 2px solid #ccc;
border-radius: 10px;
background-color: #f8f8f8;
font-size: 12px;
letter-spacing: 1.5px;
resize: none;
margin-bottom: 20px;
`;

export const FormInput = styled.input`
  ${inputStyle(30)}
`;

export const FormTextArea = styled.textarea`
  ${inputStyle(150)}
`;

export const FormLabel = styled.label`
  ${navbarFont};
`;

export const FormButton = styled.input`
  height: 50px;
  width: 100%;
  border: none;
  border-radius: 20px;
  background-color: #fda2a2;
  font-size: 16px;
  cursor: pointer;
  ${navbarFont};
`;

export const PopupContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  ${flexDisplay("auto", "auto", "column")};
  border: 2px solid #ccc;
  border-radius: 10px;
  background-color: #f8f8f8;
  padding: 10px;
  ${navbarFont};
  cursor: default;
`;

const ContactIcon = styled.img`
  width: 50px;
  height: 50px;
  padding: 10px;
  cursor: pointer;
`;

const ContactData = styled.span`
  ${navbarFont};
  font-size: 20px;
`;

const Space = styled.div`
  height: 40px;
`;

const ContactDescription = styled.span`
  ${navbarFont};
  font-size: 24px;
  margin-top: 25px;
  text-align: center;
  width: 80%;
`;

const ContactImage = styled.img`
  border-radius: 50px;
  max-width: 50%;
  ${width.mobile} {
    margin-top: 40px;
    max-width: 90%;
    border-radius: 20px;
  }
`;

export const Contact: FC = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const form = useRef<HTMLFormElement | null>(null);
  const [isMobile, setIsMobile] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<[] | null | any>(null); //TODO: add images type

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsMobile(!(document.documentElement.clientWidth > 640));
    const getData = async () => {
      try {
        const response = await getClient().getEntry("Bmj9lB7XF3mfRwnUO0Y9e");
        setData(response.fields);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    if (isPopupVisible) {
      const popupTimeout = setTimeout(() => setIsPopupVisible(false), 3000);
      return () => clearTimeout(popupTimeout);
    }
  }, [isPopupVisible]);

  const onFormSubmit = (
    event: React.ChangeEvent<HTMLFormElement>
  ): React.FormEventHandler<HTMLFormElement> | void => {
    event.preventDefault();
    const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
    const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
    const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

    if (form.current && SERVICE_ID && TEMPLATE_ID && PUBLIC_KEY) {
      emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY).then(
        () => {
          setPopupMessage("Wiadomość została wysłana");
          setIsPopupVisible(true);
          form.current?.reset();
        },
        () => {
          setPopupMessage("Przepraszamy, coś poszło nie tak");
          setIsPopupVisible(true);
        }
      );
    }
  };

  if (isLoading) return <LoadingPage />;
  if (isError) return <p>Sorry, Something went wrong!</p>;
  console.log(data);
  return (
    <Container>
      {isMobile ? <MobileNavbar /> : <Navigation />}
      <ContactDescription>{contactDescription}</ContactDescription>
      <ContactContainer>
        <Column>
          <Form ref={form} onSubmit={onFormSubmit}>
            <FormLabel>Imię i nazwisko:</FormLabel>
            <FormInput type="text" name="user_name" required />
            <FormLabel>Adres e-mail:</FormLabel>
            <FormInput type="em ail" name="user_email" required />
            <FormLabel>Wiadomość:</FormLabel>
            <FormTextArea name="message" required />
            <FormButton type="submit" value="Wyślij" />
          </Form>
        </Column>
        <Column>
          <a href={`tel:${PHONE_NUMBER}`}>
            <ContactIcon src={PhoneIcon} alt="phone-icon" />
          </a>
          <ContactData>{PHONE_NUMBER}</ContactData>
          <Space />
          <a href={`mailto:${EMAIL_ADRESS}`}>
            <ContactIcon src={EmailIcon} alt="email-icon" />
          </a>
          <ContactData>{EMAIL_ADRESS}</ContactData>
          <Space />
          <ContactIcon
            src={InstagramIcon}
            alt="instagram-icon"
            onClick={() => window.open(INSTAGRAM_URL)}
          />
          <ContactData>{INSTAGRAM_NAME}</ContactData>
        </Column>
      </ContactContainer>
      {isPopupVisible && <PopupContainer>{popupMessage}</PopupContainer>}
      <ContactImage src={data.image.fields.file.url} alt="kontakt-foto" />
    </Container>
  );
};
