import { FC, useState, useEffect } from "react";
import styled from "styled-components";

import { getClient } from "components/contentful/useContentful";
import { flexDisplay, width } from "components/helpers/helpers";
import { routes } from "consts/consts";
import { Container } from "components/siteContent/siteContent";
import { MobileNavbar } from "components/navBar/mobileNavBar";
import { Navigation } from "components/navBar/navBar";
import {
  PortfolioCard,
  portfolioTabType,
} from "components/portfolioCard/portfolioCard";
import { LoadingPage } from "components/loadingPage/loadingPage";

export interface portfolioTabsType extends Array<portfolioTabType> {}

const PortfolioContainer = styled.div`
  ${flexDisplay("90%", "auto", "row")};
  justify-content: space-evenly;
  margin-top: 25px;
  ${width.mobile} {
    ${flexDisplay("70%", "auto", "column")};
  }
`;

export const Portfolio: FC = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<[] | null | any>(null); //TODO: add images type

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getClient().getEntry("3tuqh49ple97vPGes3kzDU");
        setData(response.fields);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
      }
    };
    getData();
    window.scrollTo(0, 0);
    setIsMobile(!(document.documentElement.clientWidth > 640));
  }, []);

  if (isLoading) return <LoadingPage />;
  if (isError) return <p>Sorry, Something went wrong!</p>;

  const portfolioTabs: portfolioTabsType = [
    {
      path: routes.concerts.path,
      label: routes.concerts.label,
      image: data.koncerty.fields.file.url,
    },
    {
      path: routes.single.path,
      label: routes.single.label,
      image: data.sesje.fields.file.url,
    },
    {
      path: routes.reports.path,
      label: routes.reports.label,
      image: data.reportaze.fields.file.url,
    },
    {
      path: routes.branding.path,
      label: routes.branding.label,
      image: data.wizerunkowe.fields.file.url,
    },
  ];

  return (
    <Container>
      {isMobile ? <MobileNavbar /> : <Navigation />}
      <PortfolioContainer>
        {portfolioTabs.map(tab => (
          <PortfolioCard
            path={tab.path}
            label={tab.label}
            image={tab.image}
            key={tab.label}
          />
        ))}
      </PortfolioContainer>
    </Container>
  );
};
