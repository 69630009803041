import { FC, useEffect, useState } from "react";
import { getClient } from "components/contentful/useContentful";
import { LoadingPage } from "components/loadingPage/loadingPage";
import SiteContent from "components/siteContent/siteContent";

export const Projects: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<[] | null | any>(null); //TODO: add images type

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getClient().getEntry("1Mx7bwxPtO3UuqUOckHsEO");
        setData(response.fields);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
      }
    };
    getData();
  }, []);

  if (isLoading) return <LoadingPage />;
  if (isError) return <p>Sorry, Something went wrong!</p>;
  return (
    <SiteContent
      images={data.images.map(
        (img: { fields: { file: { url: string } } }) =>
          img.fields.file && img.fields.file.url
      )}
      description={data.description}
    />
  );
};
