import { FC, useEffect, useState } from "react";
import { getClient } from "components/contentful/useContentful";
import { LoadingPage } from "components/loadingPage/loadingPage";
import SiteContent from "components/siteContent/siteContent";
import { portfolioTabsType } from "./Porfolio";
import { routes } from "consts/consts";

export const Single: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<[] | null | any>(null); //TODO: add images type
  const [tabsImages, setTabsImages] = useState<[] | null | any>(null); //TODO: add images type

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getClient().getEntry("4EKlpwtACfLIorkuYAS6zI");
        setData(response.fields);

        const response1 = await getClient().getEntry("3tuqh49ple97vPGes3kzDU");
        setTabsImages(response1.fields);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
      }
    };
    getData();
  }, []);

  if (isLoading) return <LoadingPage />;
  if (isError) return <p>Sorry, Something went wrong!</p>;

  const portfolioTabs: portfolioTabsType = [
    {
      path: routes.concerts.path,
      label: routes.concerts.label,
      image: tabsImages.koncerty.fields.file.url,
    },
    {
      path: routes.reports.path,
      label: routes.reports.label,
      image: tabsImages.reportaze.fields.file.url,
    },
    {
      path: routes.branding.path,
      label: routes.branding.label,
      image: tabsImages.wizerunkowe.fields.file.url,
    },
  ];

  return (
    <SiteContent
      images={data.images.map(
        (img: { fields: { file: { url: string } } }) =>
          img.fields.file && img.fields.file.url
      )}
      description={data.description}
      seeAlso={portfolioTabs}
    />
  );
};
