import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { flexDisplay, width } from "components/helpers/helpers";

export interface portfolioTabType {
  path: string;
  label: string;
  image: string;
}

const PortfolioItem = styled.div`
  cursor: pointer;
  ${flexDisplay("20%", "60vh", "column")};
  ${width.mobile} {
    width: 100%;
    margin: 10px 0;
  }
`;

const PortfolioItemImage = styled.img`
  width: 100%;
  height: 50vh;
  object-fit: cover;
  border-radius: 40px 40px 0 0;
`;

const PortfolioItemLabel = styled.span`
  width: 80%;
  text-align: center;
  padding: 10%;
  color: #000000;
  background-color: #f5f2f2;
  font-family: Alata;
  border-radius: 0 0 40px 40px;
`;

export const PortfolioCard = ({ path, label, image }: portfolioTabType) => {
  const navigate = useNavigate();
  const onCardClick = () => {
    navigate(path, { replace: true });
  };

  return (
    <PortfolioItem key={label} onClick={onCardClick}>
      <PortfolioItemImage src={image} alt={image} />
      <PortfolioItemLabel>{label}</PortfolioItemLabel>
    </PortfolioItem>
  );
};
