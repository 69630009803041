export interface Route {
  path: string;
  label: string;
}

export const INSTAGRAM_URL: string =
  "https://www.instagram.com/karolina_kielczewska_foto/";

export const INSTAGRAM_NAME: string = "@karolina_kielczewska_foto";

export const PHONE_NUMBER: string = "511 968 801";

export const EMAIL_ADRESS: string = "karolinakielczewska3@gmail.com";

export const routes: Record<string, Route> = {
  mainPage: {
    path: "/",
    label: "Strona główna",
  },
  portfolio: {
    path: "/portfolio",
    label: "Portfolio",
  },
  concerts: {
    path: "/fotografia-koncertowa",
    label: "Koncerty",
  },
  single: {
    path: "/sesje-indywidualne",
    label: "Sesje indywidualne",
  },
  reports: {
    path: "/reportaze",
    label: "Reportaże, wydarzenia",
  },
  projects: {
    path: "/projekty",
    label: "Projekty",
  },
  branding: {
    path: "/sesje-wizerunkowe",
    label: "Sesje wizerunkowe",
  },
  about: {
    path: "/o-mnie",
    label: "O mnie",
  },
  contact: {
    path: "/kontakt",
    label: "Kontakt",
  },
};

export const tabs = [
  routes.portfolio,
  routes.projects,
  routes.about,
  routes.contact,
];

export const centerContent: string = `justify-content: center; align-items: center;`;

export const primaryFont: string = "font-family: Lato";

export const navbarFont: string = "font-family: Alata; ";

export const contactDescription = `Jeżeli jesteś zainteresowany/a sesją, lub masz jakieś pytania to napisz do mnie! Poniżej znajdziesz kilka możliwości kontaktu.`;

export const seeAlsoText = "Zobacz także pozostałe części mojego portfolio:";
